import { orange, red } from '@mui/material/colors'

export function envColour() {
  switch (process.env.REACT_APP_STAGE) {
    case 'local':
      return red['500']
    case 'development': // deployed (staging)
      return orange['500']
    default: // production
      return '#000000'
  }
}
